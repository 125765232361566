@font-face {
    font-family: "AvenirNextLTPro-Regular";
    src: url("/assets/fonts/AvenirNextLTPro-Regular.otf");
}

@font-face {
    font-family: "AvenirNextLTPro-Demi";
    src: url("/assets/fonts/AvenirLTStd-Medium.otf");
}

@font-face {
    font-family: "AvenirNextLTPro-Bold";
    src: url("/assets/fonts/AvenirLTStd-Black.otf");
}

@font-face {
    font-family: "AvenirNextLTPro-heavy";
    src: url("/assets/fonts/AvenirLTStd-Heavy.otf");
}
