// define font vars
$font-regular: AvenirNextLTPro-Regular;
$font-demi: AvenirNextLTPro-Demi;
$font-bold: AvenirNextLTPro-Bold;
$font-heavy: AvenirNextLTPro-heavy;
$font-icons: mroad-icons;
$font-statement-text: Arial;
$normal-font-size: 0.9rem;
$normal-font-size-mobile: 0.7rem;

// colors
$white: #fff;
$identity-primary-color: #B52125;
$input-error: #f00;
$input-valid: #008500;
$disable-color: #B8B8B8;
$list-bullet-color: #B8B8B8;
$body-background-color: #F8F8F8;
$body-background-color-darken: darken(#F8F8F8, 2%);

$chip-valid: #51B800;
$chip-inProgress: #FF9E68;
$chip-expired: #FF0303;

$interaction-color: #037CC2;
$interaction-hover-color: #A2DFFE;
$interaction-active-color: #004275;
$interaction-disabled-color: #EBEBEB;

$interaction-fg-color: #FFFFFF;
$interaction-hover-fg-color: #037CC2;
$interaction-active-fg-color: #FFFFFF;
$interaction-disabled-fg-color: #B8B8B8;

// links
$link-color: #037CC2;
$link-hover-color: #0F607C;
$link-active-color: #004275;
$link-disabled-color: #B8B8B8;
$link-visited-color: #8B59CA;

// text
$title-text-color: #474747;
$text-color: #474747;
$secondary-text-color: #717171;
$text-color-placeholder: #B8B8B8;
$text-color-placeholder-lighten: lighten(#b8b8b8, 15%);
$text-inverse: #FFFFFF;
$footer-text-color: #FFFFFF;

// Forms
$form-help-object-background: #28DAFF;

// modals
$modal-confirm-message-color: #474747;

// footer
$footer-font-size: 0.7rem;
$footer-font-color: $footer-text-color;

// header
$header-font-size: 0.9em;
$header-user-info-font-size: 0.85em;
$header-user-info-color: rgba(71, 71, 71, 0.7);

$sidebar-menu-items-border: 1px solid rgba(0, 0, 0, 0.1);

// Border
$border-color: #D8D8D8;

// Pagination
$pagintion-arrows: $interaction-color;
$pagination-item-active-background: #012A42;
$item-active-background: #7D97AA;
$item-active-color: $white;
$item-color: $text-color;

// Banner
$banner-warning-background-color: #FF8D24;
$banner-warning-color: #000;
$banner-info-background-color: #DEECFF;
$banner-info-color: #133F71;

/* Checkboxes */
$checkbox-unselected-background-color: #fff;
$checkbox-selected-background-color: #012A42;
$checkbox-unselected-border-color: #037CC2;
$checkbox-selected-border-color: #012A42;

/* Radio buttons */
$radio-btn-unselected-background-color: white;
$radio-btn-selected-background-color: #012A42;
$radio-btn-dot-color: white;
$radio-btn-unselected-border-color: #037CC2;
$radio-btn-selected-border-color: #012A42;

/* Angular Editor */
$toolbar-background-color: #F7FAFB;
$toolbar-border-color: #B8B8B8;

/* PDF Viewer */
$pdf-viewer-toolbar-background-color: #484848;
$pdf-viewer-toolbar-color: white;

/* Toast Notifications */
$toast-color-info: #133F71;
$toast-background-color-info: #DEECFF;
$toast-color-success: #005700;
$toast-background-color-success: #D9FFD9;
$toast-color-warning: #000;
$toast-background-color-warning: #FF8D24;
$toast-color-error: #A30000;
$toast-background-color-error: #FFF0F0;

