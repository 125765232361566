@import "icons.scss";
@import "vars.scss";
@import "fonts.scss";

// layout
html,
body {
  height: 100% !important;
}

body {
  font-family: $font-regular !important;
  font-size: $normal-font-size-mobile !important;
  color: $text-color !important;
  background: $body-background-color !important;
}


//-----------------------
// INPUT FIELDS
//-----------------------

.form-group {
  position: relative !important;
  margin-bottom: 1.5rem !important;

  .form-control {
    border: none !important;
    border-bottom: 1px solid $secondary-text-color !important;
    border-radius: 0 !important;
    padding-left: 2px !important;
    background-color: transparent !important;

    &:focus {
      outline: none !important;
      box-shadow: none !important;
    }

    &:disabled {
      background-color: transparent !important;
      border-bottom: 1px dashed $secondary-text-color !important;
    }

    &::placeholder {
      color: $text-color-placeholder !important;
    }
  }

  .form-control-placeholder {
    position: absolute !important;
    top: 0 !important;
    padding-top: 7px !important;
    padding-left: 2px !important;
    transition: all 200ms !important;
    opacity: 0.5 !important;
  }

  .form-control:focus + .form-control-placeholder,
  .form-control:valid + .form-control-placeholder {
    font-size: 75% !important;
    transform: translate3d(0, -100%, 0) !important;
    opacity: 1 !important;
  }

  .text-info {
    font-style: italic !important;
    font-weight: normal !important;
    font-size: 0.8rem !important;
    line-height: 1.4rem !important;
    color: #474747 !important;

    &.valid {
      color: $input-valid !important;
    }

    &.invalid {
      color: $input-error !important;
    }

    &.disabled {
      color: $interaction-disabled-fg-color !important;
    }
  }

  .picto-info {
    width: 17px !important;
    height: 17px !important;
    margin-top: 2px !important;
    color: #fff !important;
    background-color: $secondary-text-color !important;
    border: 1px solid $secondary-text-color !important;
    border-radius: 100% !important;
    line-height: 1.2rem;
    font-size: 0.8rem;
    text-align: center !important;
    float: right !important;

    &:hover {
      background-color: white !important;
      color: $secondary-text-color !important;
      border-color: $secondary-text-color !important;
    }

    &.valid {
      background-color: $input-valid !important;
      border-color: $input-valid !important;

      &:hover {
        background-color: white !important;
        color: $input-valid !important;
        border-color: $input-valid !important;
      }
    }

    &.invalid {
      background-color: $input-error !important;
      border-color: $input-error !important;

      &:hover {
        background-color: white !important;
        color: $input-error !important;
        border-color: $input-error !important;
      }
    }

    &[title]:hover:after {
      content: attr(title) !important;
      position: absolute;
      right: 5px;
      top: -80px;
      width: 190px;
      height: 80px;
      padding: 5px;
      border: 1px solid $interaction-disabled-color;
      box-sizing: border-box;
      border-radius: 5px;
      background-color: #f7fafb;
      color: black;
      font-style: italic;
      font-size: 0.6rem;
      text-align: justify;
    }
  }

  select {
    -webkit-appearance: none;
    -moz-appearance: none;
    -o-appearance: none;

    option:not(disabled) {
      color: $text-color;
    }

    option:disabled:not(selected) {
      color: $text-color-placeholder;
    }
  }

  select + i {
    float: right;
    margin-top: -25px;
    margin-right: 5px;
    pointer-events: none;
    background-color: transparent;
    color: $interaction-color;
    padding-right: 5px;
  }
}

//-----------
// BREADCRUMB
//-----------

.breadcrumb-item {
  a {
    text-decoration: underline;
  }

  a:not(.btn-link):not(.nav-link):visited, .nav-link:not(.nav-link):visited {
    color: #037CC2 !important;
  }
}

//------------------------------------
// RADIO BUTTON - CHECKBOXES - TOGGLES
//------------------------------------

.form-check {
  .label {
    display: block;
    position: relative;
    padding-left: 25px;
    cursor: pointer;
    color: $secondary-text-color;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }

  .form-check-input {
    margin-top: 0.1rem !important;
  }

  //-------------
  // RADIO BUTTON
  //-------------

  /* Hide the browser's default radio button */
  .label input[type="radio"],
  .label input[type="checkbox"] {
    position: absolute;
    opacity: 0;
    cursor: pointer;
  }

  /* Create a custom radio button */
  .checkmark-radio {
    position: absolute;
    top: 2px;
    left: 0;
    height: 15px;
    width: 15px;
    background-color: white;
    border: 1px solid $radio-btn-unselected-border-color;
    border-radius: 50%;
  }

  /* Create the indicator (the dot/circle - hidden when not checked) */
  .checkmark-radio:after {
    content: "";
    position: absolute;
    display: none;
  }

  /* Show the indicator (dot/circle) when checked */
  .label input:checked ~ .checkmark-radio:after {
    display: block;
  }

  /* Style the indicator (dot/circle) */
  .label .checkmark-radio:after {
    top: 3px;
    left: 3px;
    width: 7px;
    height: 7px;
    border-radius: 50%;
    background: $radio-btn-dot-color;
  }

  /* When the radio button is checked, add a blue background */
  .label input:checked ~ .checkmark-radio {
    background-color: $radio-btn-selected-background-color;
    border-color: $radio-btn-selected-background-color;
  }

  /* When the radio button is disabled and no checked background is white and border more lighten */
  .label input:disabled ~ .checkmark-radio {
    border-color: lighten($text-color-placeholder, 15%);
    background-color: white !important;
  }

  /* When the radio button is disabled and checked, the label is more lighten  */
  .label.disabled {
    color: lighten($text-color-placeholder, 15%);

    input:checked ~ .checkmark-radio {
      background-color: lighten($text-color-placeholder, 15%) !important;
    }
  }

  //-----------
  // CHECKBOXES
  //-----------

  /* Create a custom checkbox */
  .checkmark-checkbox {
    position: absolute;
    top: 2px;
    left: 0;
    height: 15px;
    width: 15px;
    background-color: white;
    border: 1px solid $text-color-placeholder;
    border-radius: 3px;
  }

  /* Create the checkmark-checkbox/indicator (hidden when not checked) */
  .checkmark-checkbox:after {
    position: absolute;
    content: "\e903";
    font-family: "mroad-icons" !important;
    font-size: 0.9rem;
    color: $secondary-text-color;
    display: none;
  }

  /* Style the checkmark-checkbox/indicator */
  .label .checkmark-checkbox:after {
    left: 0px;
    top: -4px;
  }

  /* When the checkbox is checked, add a grey background */
  .label input:checked ~ .checkmark-checkbox {
    background-color: $text-color-placeholder-lighten;
  }

  /* When the checkbox is disabled and checked, the label is more lighten  */
  .label.disabled {
    color: $text-color-placeholder-lighten;

    input:checked ~ .checkmark-checkbox {
      background-color: $text-color-placeholder-lighten;
    }
  }

  .label.disabled .checkmark-checkbox:after {
    color: $text-color-placeholder;
  }

  /* Show the checkmark-checkbox when checked */
  .label input:checked ~ .checkmark-checkbox:after {
    display: block;
  }
}

.custom-control-input {
  z-index: 1 !important;
}

.custom-control-label {
  padding-top: 0.2rem !important;
}

.custom-control-label:before {
  background-color: $checkbox-unselected-background-color;
  border-color: $checkbox-unselected-border-color;
}

.custom-checkbox .custom-control-input:checked ~ .custom-control-label::before {
  background-color: $checkbox-selected-background-color;
  border-color: $checkbox-selected-border-color;
}

//--------------
// TOGGLE BUTTON
//--------------

.switch-label {
  position: relative;
  top: -7px;
  margin: 0 10px;
  font-weight: bold;
  font-size: 16px;
  color: #717171;

  &.disabled {
    color: $interaction-disabled-fg-color;
  }
}

.switch {
  position: relative;
  display: inline-block;
  width: 30px;
  height: 10px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: $text-color-placeholder;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 16px;
  width: 16px;
  left: 0;
  bottom: -2px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
  -webkit-box-shadow: 0 1px 3px rgba(0, 0, 0, 0.8);
  -moz-box-shadow: 0 1px 3px rgba(0, 0, 0, 0.8);
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.8);
}

input:checked + .slider:before {
  background-color: white;
  left: -10px;
}

input:checked + .slider {
  background-color: $interaction-color;
}

input:disabled + .slider {
  background-color: $interaction-disabled-color;
}

input:focus + .slider {
  box-shadow: 0 0 1px #2196f3;
}

input:checked + .slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}

//---------------
// FILTER BUTTONS
//---------------

.filter-toolbar {
  background-color: $text-color-placeholder;
  border-radius: 20px;

  &.disabled {
    background-color: lighten($text-color-placeholder, 15%) !important;
  }

  .btn {
    width: 130px;
    height: 25px;
    margin: 5px 1px;
    background-color: transparent;
    border: none;
    font-size: 16px !important;
    line-height: 0;
    color: #000;

    &:first-child {
      margin-left: 5px !important;
    }

    &:last-child {
      margin-right: 5px !important;
    }
  }

  .btn:hover,
  .btn-link:hover {
    border: none !important;
    background-color: white !important;
    color: #037cc2 !important;
  }

  .btn:not(:disabled):not(.disabled):active,
  .btn:not(:disabled):not(.disabled).active,
  .btn:not(:disabled):not(.disabled):focus,
  .btn-link:not(:disabled):not(.disabled):active,
  .btn-link:not(:disabled):not(.disabled).active,
  .btn-link:not(:disabled):not(.disabled):focus {
    border: none !important;
    background-color: white !important;
    color: black !important;
  }
}

.filter-toolbar.disabled {
  .btn {
    color: $text-color-placeholder !important;

    &:hover {
      background-color: lighten($text-color-placeholder, 15%) !important;
      cursor: default;
    }
  }

  .btn:not(:disabled):not(.disabled):active,
  .btn:not(:disabled):not(.disabled).active,
  .btn:not(:disabled):not(.disabled):focus,
  .btn-link:not(:disabled):not(.disabled):active,
  .btn-link:not(:disabled):not(.disabled).active,
  .btn-link:not(:disabled):not(.disabled):focus {
    border: none !important;
    background-color: lighten($text-color-placeholder, 15%) !important;
    color: $text-color-placeholder !important;
  }
}

//---------
// DROPDOWN
//---------

.dropdown-custom {
  .btn-primary,
  .btn-outline-primary {
    width: 320px;
    height: 30px;
    color: $interaction-hover-fg-color;
    border-color: $interaction-hover-fg-color;
    background-color: white;
    font-size: 12px;
    text-align: left;

    [class^="mroad-ico-"] {
      float: right;
    }

    &:hover {
      background-color: $interaction-hover-fg-color !important;
      border: 1px solid $interaction-hover-fg-color !important;
      color: white !important;
    }
  }

  .dropdown-toggle::after {
    border-top: 0;
  }

  .dropdown-toggle.btn.btn-outline-primary::after {
    position: absolute;
    top: 5px;
    right: 8px;
    font-family: "mroad-icons";
    font-size: 0.9rem;
  }

  .dropdown-toggle.btn.btn-outline-primary[aria-expanded="false"] {
    &::after {
      content: "\e904" !important;
      color: $interaction-hover-fg-color !important;
    }
  }

  .dropdown-toggle.btn.btn-outline-primary[aria-expanded="true"] {
    &::after {
      content: "\e907" !important;
      color: white !important;
    }
  }

  .dropdown-custom .btn:focus,
  .btn:not(:disabled):not(.disabled):focus,
  .btn:not(:focus) {
    background-color: white !important;
    border: 1px solid $interaction-hover-fg-color !important;
    color: $interaction-hover-fg-color !important;
  }

  .dropdown-toggle.btn.btn-outline-primary[aria-expanded="true"] {
    border: none !important;
    background-color: $interaction-hover-fg-color !important;
    color: white !important;
    border-bottom-left-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
  }

  .btn-primary::before,
  .btn-outline-primary::before {
    content: "";
  }

  .dropdown-menu.show {
    position: relative !important;
    left: 0;
    width: 320px;
    border-color: $interaction-hover-fg-color;
    will-change: transform !important;
    transform: translate(0, -5px) !important;
  }
}

//----------------
// LINKS & BUTTONS
//----------------
a:not(.btn-link),
.nav-link {
  color: $link-color !important;

  &:visited {
    color: $link-color !important;
  }

  &:hover {
    color: $link-hover-color !important;
    cursor: pointer;
  }

  &.active,
  &:active {
    color: $link-active-color !important;
  }

  &.disabled,
  &:disabled {
    color: $link-disabled-color !important;
  }

  &:not(.nav-link):visited {
    color: $link-visited-color !important;
  }
}

a:not(.btn-link):not(.nav-link):visited, .nav-link:not(.nav-link):visited {
  color: $link-color !important;
}

$btn-border-radius: 1.25rem !default;
$btn-border-radius-lg: 1.25rem !default;
$btn-border-radius-sm: 1.25rem !default;
$btn-focus-box-shadow: none !default;

// bootstrap buttons
.btn-primary,
.btn-outline-primary {
  text-transform: uppercase !important;
  font-size: 0.75rem !important;

  &::before {
    font-family: $font-icons !important;
    font-size: 0.75rem;
    padding-right: 5px;
  }
}

.btn-primary {
  background-color: $interaction-color !important;
  color: $interaction-fg-color !important;

  &::before {
    content: "\e905";
    font-family: $font-icons !important;
    padding-right: 0.3rem;
  }
}

.btn,
.btn-link {
  outline: none !important;
  box-shadow: none !important;

  &:hover {
    border-color: $interaction-hover-color !important;
    background-color: $interaction-hover-color !important;
    color: $interaction-hover-fg-color !important;
    text-decoration: none !important;
  }

  // Disabled comes first so active can properly restyle
  &.disabled,
  &:disabled {
    border: none !important;
    background-color: $interaction-disabled-color !important;
    color: $interaction-disabled-fg-color !important;
  }
}

//-----------------------
// TYPOGRAPHY
//-----------------------
h1,
h2,
h3,
h4,
h5,
h6 {
  color: $title-text-color;
}

h1 {
  font-size: 1.5rem !important;
}

h2 {
  font-size: 1.2rem !important;
}

h3 {
  font-size: 1rem !important;
}

h4 {
  font-size: 0.9rem !important;
  font-weight: bold !important;
}

h5 {
  font-size: 0.9rem !important;
}

h6 {
  font-size: 0.8rem !important;
}

.metadata,
.text-secondary {
  font-size: 0.875rem !important;
  color: $secondary-text-color !important;
}

.italic {
  font-style: italic;
}

.bold {
  font-family: $font-bold !important;
}

.demi {
  font-family: $font-demi !important;
}

//-----------------------
// Bullet list
//-----------------------
li {
  color: $list-bullet-color !important;

  & span {
    color: $text-color !important;
  }
}

//-----------------------
// FOOTER
//-----------------------
footer {
  color: $footer-text-color !important;
}

@media screen and (min-width: 376px) {
  body {
    font-family: $font-regular !important;
    font-size: $normal-font-size !important;
    color: $text-color;
  }

  h1 {
    font-size: 1.875rem !important;
  }

  h2 {
    font-size: 1.5rem !important;
  }

  h3 {
    font-size: 1.25rem !important;
  }

  h4 {
    font-size: 1.125rem !important;
    font-weight: bold !important;
  }

  h5 {
    font-size: 1.125rem !important;
  }

  h6 {
    font-size: 1rem !important;
  }
}

//-----------------------
// TOAST MESSAGES
//-----------------------
#toast-container {
  & .toast-error {
    background-color: #e10000;
    color: #ffffff;
  }

  & .toast-success {
    background-color: #008500;
    color: #ffffff;
  }

  & .toast-warning {
    background-color: #FFB228;
    color: #474747;
  }

  & .toast-info {
    background-color: #28DAFF;
    color: #474747;
  }
}

// BREADCRUMB
.breadcrumb {
  background-color: transparent !important;
  padding-left: 0 !important;
}

.breadcrumb li.breadcrumb-item a:not(:active) {
  color: $link-color !important;
  text-decoration: underline;
}

.breadcrumb-item + .breadcrumb-item::before {
  content: ">" !important;
}

.breadcrumb li.breadcrumb-item a:visited {
  color: var(--link-color) !important;
  text-decoration: underline !important;
}

#toast-container {
  .toast-success {
    background-color: $toast-background-color-success !important;
    color: $toast-color-success !important;
  }

  .toast-error {
    background-color: $toast-background-color-error !important;
    background-image: url('/assets/images/icons/png/error.png') !important;
    color: $toast-color-error !important;
  }

  .toast-info {
    background-color: $toast-background-color-info !important;
    background-image: url('/assets/images/icons/png/info.png') !important;
    color: $toast-color-info !important;
  }

  .toast-warning {
    background-color: $toast-background-color-warning !important;
    background-image: url('/assets/images/icons/png/exclamation.png') !important;
    color: $toast-color-warning !important;
  }
}
